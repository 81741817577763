.info-content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: #E1E1E1;
}

#text-p {
    /* width: 50%; */
    text-align: center;
    margin: 5%;
    font-size: 15px;
}
a {
    font-size: large;
}

.info-btn-2-home {
    border: none;
    width: 140px;
    height: 50px;
    border-radius: 25px;
    background: var(--jauneClaire);
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
}

.info-btn-2-home:hover {
    border: 1px solid black;
}