.tarif-checkbox-img-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.tarif-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.tarif-btn-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* width: 150px; */
    margin-left: 30px;
    margin-right: 30px;
}

.tarif-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tarif-btn-save:hover {
    /* background: white; */
    /* border: 3px solid #312f2f; */
    /* color: #312f2f; */
    /* cursor: pointer; */
}
.tarif-btn-save {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--vertFonceAssistant);
    padding: 12px;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-weight: normal;
    width: 145px;
    height: 50px;
    font-size: math;
    margin-top: 15px;
}
.tarif-btn-verification:hover {
    background: white;
    border: 3px solid #312f2f;
    color: #312f2f;
    cursor: pointer;
}
.tarif-btn-verification {
    display: flex;
    align-items: center;
    justify-content: center;
    background: gray; /*var(--vertFonceAssistant);*/
    padding: 12px;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-weight: normal;
    height: 50px;
    font-size: math;
    margin-top: 15px;
}