.img-checkbox-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--grisSombre);
    width: 25%;
    border-radius: 10px;
    padding: 25px;
    margin: 5px;
}
.img-checkbox-cardimg-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: #ffffffa6;
    padding: 10px;
    border-radius: 50px;
}
.img-checkbox-desc-container {
    display: flex;
    flex: 4;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#img-checkbox-label-title {
    display: flex;
    flex: 1;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}
#img-checkbox-label-desc {
    display: flex;
    flex: 3;
    text-align: center;
    color: white;
}
.img-checkbox-input-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grisClaire);
    padding: 12px;
    border-width: 0;
    border-radius: 10px;
    color: var(--jauneClaire);
    font-weight: bold;
    width: 110px;
    height: 45px;
}

#img-checkbox-label-title:hover {
    cursor: pointer;
}
.img-checkbox-input-label-container:hover {
    background: white;
    border: 3px solid #312f2f;
    color: #312f2f;
    cursor: pointer;
}
label:hover {
    cursor: pointer;
}

#price {
    display: flex;
    flex: 1;
    font-size: x-large;
    color: white;
}