.error-content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    /* background: #54595F; */
}
#error-title {
    color:#e32424;
    font-size: 50px;
}
h3 {
    color: black;
}
a {
    font-size: large;
    color: white;
}
.error-btn-2-home {
    border: none;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    background: var(--vertFonceTransp);
    font-weight: bold;
    cursor: pointer;
}

.error-btn-2-home:hover {
    border: 1px solid black;
}