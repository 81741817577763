@import url("./Colors.css");
/* Modal Overlay */
.code-verif-modal-overlay {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* semi-transparent black */
    background-image: url(/public/images/fond/sso_main_bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

/* Modal */
.code-verif-modal {
    display: flex;
    width: 400px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #0A0A0A1F;
    padding: 20px;
    border-radius: 11px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(189, 189, 189, 0.6);
    opacity: 1;
}

/* Input Squares */
.input-container {
    display: flex;
    justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
#input-six-number ::-webkit-outer-spin-button,
#input-six-number ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#input-six-number [type=number] {
  appearance: textfield;
}

.title-container {
    display: flex;
    justify-content: center;
    font-size: medium;
    text-align: center;
    color: #E9FFEB;
    margin: 10px;
}

.input-square {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    text-align: center;
    font-size: 1.2em;
    padding: 0;
    /* font-weight: bold; */
}

.input-square2 {
    width: 165px;
    height: 50px;
    margin: 0 5px;
    text-align: center;
    font-size: 1.2em;
    font-weight: lighter;
}
input::placeholder {
    font-size: 1.0rem;
}

/* Validate Button */
.validate-button-container {
    display: flex;
    width: 125px;
    margin: 15px;
}

.validate-button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--violet);
    color: white;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
}

.validate-button:disabled {
    background-color: #ccc; /* gray */
    cursor: not-allowed;
}

.code-verif-title {
    text-align: center;
    color: #E9FFEB;
}

.code-verif-logo {
    margin-top: 15px;
    margin-bottom: 15px;
}