/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
    max-height: 75vh; /* 550px;*/
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
    /* display: none; */
    scrollbar-width: thin;
} 