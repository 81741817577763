.main-content-container-un {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #eee, #aaa);
}

h1.lisa-un-title {
    text-align: center;
    margin: 40px 0 20px;
}

.lock {
    display: flex;
    margin-top: 50px;
    align-items: center;
}

.lisa-icon-user-un {
    width: 50px;
}


@keyframes lock {
    0% {
        top: -45px;
   }
    65% {
        top: -45px;
   }
    100% {
        top: -30px;
   }
}
@keyframes spin {
    0% {
        transform: scaleX(-1);
        left: calc(50% - 30px);
   }
    65% {
        transform: scaleX(1);
        left: calc(50% - 12.5px);
   }
}
@keyframes dip {
    0% {
        transform: translateY(0px);
   }
    50% {
        transform: translateY(10px);
   }
    100% {
        transform: translateY(0px);
   }
}