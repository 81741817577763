.email-form-container {
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.email-form-header {
  text-align: center;
  margin-bottom: 20px;
}

.email-form-section {
  background: #80808012;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
}

.email-form-section h3 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.email-form-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  color: black;
}

.email-form-textarea:focus {
  border: solid;
}

.email-form-textarea::placeholder {
  color: gray;
}

.email-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}

.email-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  margin-bottom: 10px;
}

.email-form-button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.email-form-button:hover {
  background: #332E16E5;
}

.email-form-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: gray;
}

/* Conteneur principal qui s'adapte dynamiquement */
.email-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* Mode centré quand il n'y a pas de résultats */
.email-form-center {
  flex-direction: column;
  align-items: center;
}

/* Mode split en 2/3 - 1/3 quand il y a des résultats */
.email-form-split {
  flex-direction: row;
}

/* Formulaire - 2/3 de l'écran si résultats affichés */
.email-form-container {
  flex: 2;
  background: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 700px;
}

/* Contenu du formulaire qui peut défiler */
.email-form-content {
  flex: 1;
  overflow-y: auto;
  max-height: 400px; /* Ajuste la hauteur max du formulaire */
  padding-bottom: 60px; /* Espace pour le bouton */
}

/* Bouton fixe en bas */
.email-form-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 12px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Bouton */
.email-form-button {
  background: #332E16E5;
  color: #fce77b;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
}

.email-form-button:hover {
  background: #332E16E5;
}

/* Résultats - 1/3 de l'écran */
.email-result-container {
  flex: 1;
  background: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Titre des résultats */
.email-result-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
}

/* Liste des résultats */
.email-result-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-result-item {
  background: #ffffff;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.email-result-question {
  font-weight: bold;
}

.email-result-answer,
.email-result-score {
  font-weight: bold;
  color: #9c8a2e;
}

/* Gestion responsive */
@media (max-width: 768px) {
  .email-form-wrapper {
    flex-direction: column;
  }
  
  .email-form-container, .email-result-container {
    width: 100%;
  }
}

.feedback-container {
  margin-top: 15px;
}
.feedback-btn {
  border: none;
  border-radius: 15px;
  margin: 5px;
  background: transparent;
}

.text-eval {
  font-weight: bold;
  color: white;
  font-style: oblique;
}