#creneau-container {
    display: flex;
    flex: 3;
    flex-direction: column;
}
.mail-prep-row-1 {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.mail-prep-col-1-3 {
    display: flex;
    flex: 1;
}
.mail-prep-left-picto {
    display: flex;
    /* flex: 1; */
    width: 50px;
    height: 50px;
    /* border: 1px solid;
    border-radius: 15px; */
    justify-content: center;
    align-items: center;
}
.mail-prep-right-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* align-items: center; */
    padding-left: 10px;
}
.mail-prep-picto-meeting {
    width: 30px;
    height: 30px;
}
#mail-prep-form-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    margin: 5%;
    border: 2px solid gray;
    border-radius: 20px;
    padding: 1%;
}

#mail-prep-form-left-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* background: rgb(251, 245, 245); */
    padding: 2%;
}
#mail-prep-form-separator-line {
    display: flex;
    width: 1px;
    background: #757373;
}
#mail-prep-form-right-content {
    display: flex;
    flex: 2;
    flex-direction: row;
    /* background: rgb(232, 227, 227); */
    padding: 2%;
}
#mail-prep-title {
    display: flex;
    flex: 1;
    justify-content: left;
    font-size: 25px;
}
#mail-prep-form-input{
    display: flex;
    flex-direction: column;
    flex: 4;
}
.mail-prep-row{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.mail-prep-col1{
    display: flex;
    flex: 1;
    flex-direction: row;
}

#mail-prep-form-btn-container{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
#mail-prep-form-btn {
    background: #1d572f;
    padding: 15px;
    font-size: medium;
    border: 1px solid transparent;
    border-radius: 15px;
    color: white;
}
#mail-prep-form-btn:hover {
    cursor: pointer;
    background: white;
    padding: 15px;
    font-size: medium;
    border: 1px solid  #1d572f;
    border-radius: 15px;
    color: #1d572f;
}
#mail-prep-custom-select {
    padding: 10px;
    background: transparent;
    border: 1px solid #978e8e;
    border-radius: 10px;
}
.textarea-as-input {
    background: white;
    border-radius: 15px;
    border: 1px solid gray;
    font-size: 14px;
    padding: 15px 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    color: black;
    white-space: pre-line;
    font-family: 'Arial', sans-serif;
    font-weight:500;
}
.textarea-as-input::placeholder {
    color: gray;
    font-weight:600;
    font-family: 'Arial', sans-serif;
}
.textarea-as-input:focus {
    /* outline: none; */
    border-color: gray;
}