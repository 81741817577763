.mail-body-displaying {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.css-1wnsr1i {
    max-height: 85vh;
    overflow: auto;
    scrollbar-width: thin;
    min-width: 65vw;
}