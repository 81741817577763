@import url("./Colors.css");
/* Remove browser defaults */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.TabWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
/* Tab Container */
.Tabs {
    width: 80%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #E8F0F2;
    border-radius: 2rem;
    @media (max-width: 769px) {
     padding: 2rem 0;
    }
}

ul.aurelia-nav-menu li.no-css-image {
  width: 100%;
  resize: block;
  border: none;
  border-radius: 0px;
  margin:auto;
}
ul.aurelia-nav-menu li.no-border {
  border-color: var(--bleuClaireVerdatre);
}
ul.aurelia-nav-menu li.no-border img{
  border: none;
}

ul.aurelia-nav-menu li.no-css-image img{
  width: 100%;
  resize: block;
  border: none;
  border-radius: 0px;
  background-color: transparent;
}

ul.aurelia-nav-menu {
    padding: 5px;
    @media (max-width: 768px) {
      width: 90%;
    }
}

ul.aurelia-nav-menu li {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border: 1px solid var(--vertClaire);
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  /* border-bottom-left-radius: 2rem; */
  /* border-top-left-radius: 2rem; */
  color: white;
  font-weight: bold;
  height: 40px;
}
ul.aurelia-nav-menu li img {
  resize: block;
  /* border: 1px solid black;
  border-radius: 20px; */
  /* background: white; */
}

ul.aurelia-nav-menu li{
  display: flex;
  flex: 3;
  font-size: medium;
  color: black;
  font-weight: 100;
}

ul.aurelia-nav-menu li.no-css-image:hover{
  background: transparent;
  border: none;
  cursor: default;
  color:black;
}
ul.aurelia-nav-menu li:hover {
  border: 1px solid rgba(255, 255, 255, 0.979);
  /* background: var(--vertFonce); */
  background: white;
  color: black;
}
ul.li.img:hover{
  border-color: black;
  color: black;
}
ul.aurelia-nav-menu li.deactive:hover {
  /* background: var(--vertFonceTransp); */
  background: var(--grisClaire);
  border: 1px solid white;
}

ul.aurelia-nav-menu.enable-hover:hover {
  border: none;
}
.aurelia-nav-menu.disable-hover li:hover p:hover{
  background-color: transparent; /* Pas d'effet de hover */
  cursor: not-allowed; /* Aligné avec le style désactivé */
  border: none;
  color: white;
}

ul.aurelia-nav-menu li.deactive p:hover{
  color: black;
}
.aurelia-text-nav-menu:hover {
  color: black;
}
ul.aurelia-nav-menu li.deactive img:hover {
  color: black;
}

ul.aurelia-nav-menu li.active {
  background: var(--grisClaire);
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 1rem;
  border: 2px solid var(--jauneClaire);
}
ul.aurelia-nav-menu li.active p{
  /* color: var(--texteVertClaire); */
  color: white;
  font-weight: bold;
}
ul.aurelia-nav-menu li.deactive p{
  /* color: var(--gris); */
  color: white;
}

ul.aurelia-nav-menu li.deactive {
  /* background: var(--vertFonceTransp); */
  border: none;
}

ul.aurelia-nav-menu li.grise {
  background: var(--vertFonceTransp);
  border: none;
}
ul.aurelia-nav-menu li.grise p{
  color: var(--gris);
}

.aurelia-text-nav-menu {
  display: flex;
  flex: 1;
  justify-content: center;
  font-weight: normal;
  color: white;
}
