.campagne-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.campagne-two-btn {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.campagne-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 170px;
    margin: 10px;
    border-radius: 100px;
    cursor: pointer;
    border: 1px solid #312f2f;
    flex-direction: column;
    background: var(--jauneClaire);
}

.campagne-second-part{
    display: flex;
    flex: 4;
    flex-direction: column;
}
.campagne-tab-btn-ux {
    display: flex;
}
.campagne-tab-btn-icon-container {
    display: flex;
    justify-content: flex-end;
    border: 1px solid ;
}
.campagne-tab-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center ;
    background: transparent;
    padding: 5px;
    border: none;
}

.campagne-btn-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grisSombre);
    padding: 12px;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-weight: bold;
}

.campagne-btn-container:hover {
    background: white;
    border: 5px solid var(--jauneClaire);
}
.campagne-btn-image:hover {
    border-color: white;
}
.campagne-btn-title:hover {
    background: white;
    border: 3px solid #312f2f;
    color: #312f2f;
}
.campagne-modal-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.campagne-modal-container p {
    color: black;
}
.campagne-modal-input-btn-mailnumber {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.campagne-text {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    padding: 15px;
    margin: 5px;
    background: rgba(230, 230, 230, 0.53);
    border-radius: 10px;
    color: black;
}