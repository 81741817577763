@import url("./Colors.css");
.lang-menu {

}
.lang-menu ul {
    margin: 0;
    padding: 0;
    width: 140px;
    background-color: #fff;
    border: 1px solid #f8f8f8 ;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: none;
    position: absolute;
}
.lang-menu ul li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}
.lang-menu ul li p {
    width: 140px;
    display: block;
    padding: 5px 5px;
}
.lang-menu ul li p::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 32px;
    height: 25px;
}
.lang-menu ul li p:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}
.selected-lang {
    cursor: pointer;
    display: flex;
    line-height: 2;
    width: 120px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--grisSombre);
    padding: 5px;
    border-radius: 15px;
}
.selected-lang::before {
    content: "";
    display: block;
    width: 32px;
    height: 25px;
    /* background-image: url("/public/images/flag/france.jpeg"); */
}

.fr::before {
    background-image: url("/public/images/flag/france.jpeg");
}
.us::before {
    background-image: url("/public/images/flag/usa.jpeg");
}
.pt::before {
    background-image: url("/public/images/flag/portugal.jpeg");
}

.lang-menu:hover ul{
    display: block;
}