@import "Colors.css";

.checkbox {
    padding: 10px;
}
.header {
    padding: 0;
}
.RTUI .THead .Row {
    /* background: green; */
}
.RTUI .TitleBar {
    background: var(--violet);
}
.css-204u17-MuiDataGrid-main{
    background: #BDB58C4D;
}
.css-jozl7f-MuiDataGrid-root .MuiDataGrid-cellContent {
    color: white;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}
/* .css-1iyq7zh-MuiDataGrid-columnHeaders { */
.css-1iyq7zh-MuiDataGrid-columnHeaders{
    background: var(--bleuClaireMoinsVert);
}

.css-12ozfd4 {
    height: 100%;
    width: 100%;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    border: 1px solid;
    border-color: black;
}