@import "Colors.css";

.sso-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
.sso-login-container{
  width: 400px;
  background-color: rgba(255,255,255,0.13);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(189, 189, 189, 0.6);
  padding: 50px 35px;
}

.sso-login-container-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.sso-login-title {
  text-align: center;
  margin: 10px;
  color: var(--jauneClaire);
}

.sso-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sso-google-btn, .sso-facebook-btn {
  height: 40px;
  max-width: 226px;
  width: 226px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.sso-google-login {
  position: relative;
  overflow: hidden;
  border: none;
  cursor: pointer;
  animation: borderTransition 5s linear infinite paused;
}

.sso-google-login.animated {
  animation-play-state: running;
  border: 3px solid transparent;
  border-radius: 8px;
  cursor: progress;
}

@keyframes borderTransition {
  0% {
    border-color: #4285F4;
  }
  50% {
    border-color: #DB4437;
  }
  100% {
    border-color: #42f4c7;
  }
}

.sso-facebook-btn {
  background-color: #3A559F;
  color: #fff;
}

.sso-container {
  width: 100%;
  background-image: url(/public/images/fond/sso_main_bg.svg);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.sso-description-container {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 400px;
  margin: 10px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(189, 189, 189, 0.6);
}
.sso-description-text {
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

canvas[id^='react-unity-webgl-canvas-'] {
  width: 100%;
  height: 100vh;
}