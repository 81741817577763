/* CampaignLauncher.css */
.campaign-launcher {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.campaign-main {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.campaign-head {
    padding: 1rem;
    position: relative;
}

.campaign-head-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.rocket-icon {
    background: var(--jauneClaire);
    padding: 0.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.campaign-head h1 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.campaign-description {
    flex: 1;
}

.campaign-body {
    padding: 1.5rem;
}

/* Style pour le formulaire dans le body */
.campaign-body label {
    flex: 1;
    display: block;
    /* margin-bottom: 0.5rem; */
    font-weight: 500;
    color: #374151;
}

.campaign-body input {
    flex: 2;
    width: 100px;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.campaign-body span {
    color: #6b7280;
}

.campaign-btn-container {
    padding: 1rem 1.5rem 1.5rem;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
}

.campaign-form-btn {
    padding: 0.75rem 2rem;
    border-radius: 6px;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    /* min-width: 120px; */
    width: 100%;
}

.campaign-form-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style pour le loader */
.campaign-btn-container > div {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
}

.campaign-btn-container p {
    margin: 0;
    font-size: 0.95rem;
}

/* Media Queries pour la responsivité */
@media (max-width: 640px) {
    .campaign-main {
        margin: 1rem;
    }

    .campaign-head h1 {
        font-size: 1.25rem;
    }

    .campaign-body {
        padding: 1rem;
    }

    .campaign-form-btn {
        width: 100%;
    }
}