@import url("./Colors.css");

.dest-search-container {
    display: flex;
    flex: 3;
    flex-direction: column;
}
.dest-search-left-container {
    display: flex;
    flex: 1;
}
.dest-result-checkbox-container {
    display: flex;
    /* flex: 1; */
    align-items: center;
    background: #817CFC26;
    margin: 1px;
    height: 25px;
    width: fit-content;
    padding: 5px;
    border-radius: 10px;
}
.dest-optionnal-left-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.dest-optionnal-right-container {
    display: flex;
    flex: 2;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-search-right-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-form-input-left{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-form-input-include{
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-form-input-exclude{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-separator {
    display: flex;
    height: 100%;
    width: 1px;
    background: black;
}
.dest-form-input-right{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px;
}
.dest-form-btn-container{
    display: flex;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.dest-location-search-result {
    display: flex;
    flex: 1;
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    height: auto;
    flex-direction: column;
}

.dest-location-scroll {
    max-height: auto;
    overflow-y: auto; /* scroll */
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}
.dest-location-scroll-search-result {
    max-height: 60px;
    overflow-y: auto; /* scroll */
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.dest-location-scroll::-webkit-scrollbar {
    display: auto;
    scrollbar-width: thin;
} 

.dest-search-result-container {
    display: flex;
    flex: 1;
    /* align-items: center; */
}
.dest-optionnel {
    display: flex;
    flex: 1;
}

.dest-form-input-location{
    display: flex;
    flex: 2;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    background: white;
}
.dest-form-label-location {
    display: flex;
}

.dest-form-input-location-main-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.dest-form-input-location-container {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.label-checkbox {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30vh;
}