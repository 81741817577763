.checkbox-container {
    display: flex;
    flex: 1;
    align-items: center;
}
.mail-checkbox-two-section {
    display: flex;
    flex: 1;
}

/* Hide scrollbar for IE, Edge and Firefox */
.checkbox-scroll {
    max-height: 370px;
    overflow-y: scroll;
    -ms-overflow-style:auto;  /* IE and Edge */
    scrollbar-width:auto;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.checkbox-scroll::-webkit-scrollbar {
    display: auto;
}

.checkbox-case {
    height: 25px;
    width: 25px;
}

input[type='checkbox']:checked {
    background-color: white;
}
input[type='checkbox']:checked:after {
    content: '\2713';
    color:rgb(118, 54, 161);
}
input[type='checkbox']{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    appearance:none;
    border-radius:10%;
    border: 1px solid gray;
    box-shadow: none;
    font-size: large;
    font-weight: bold;
}
input[type='checkbox']:hover {
    cursor: pointer;
}