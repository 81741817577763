.multiline-input-container {
    margin: 5px;
    width: 100%;
}
  
.multiline-input::-webkit-scrollbar {
  width: 100%; /* Take up 100% width of the container */
  height: 100%; /* Take up 100% height of the container */
  resize: none; /* Désactive la possibilité de redimensionner le textarea */
  border: 2px solid #ccc;
  padding: 8px;
  font-size: 15px;
  display: none;
}
  
.multiline-input {
  width: 100%; /* Take up 100% width of the container */
  height: 100%; /* Take up 100% height of the container */
  resize: none; /* Désactive la possibilité de redimensionner le textarea */
  border: none;
  font-size: 15px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background: #1c1c1cbd;
  color: white;
}
textarea {
  background: transparent;
  border-radius: 0;
  border: none;
  /* border-bottom: 1px solid #ededed; */
  font-size: 14px;
  line-height: 1.5;
  padding: 5px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  color: #ffffff;
}
textarea:hover {
  border-color: #c6c6c6;
}

textarea:focus {
  outline: none;
  border-color: #ffffff;
}

textarea::placeholder {
  color: #ffffff;
}