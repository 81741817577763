.icone-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    padding: 3px;
    border-radius: 15px;
    margin-bottom: 10px;
}
#icone-btn-image-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}