.img-btn-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    padding: 3px;
    border-radius: 15px;
    margin-bottom: 10px;
}
#img-btn-image-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
#img-btn-text-container {
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
}
#img-btn-text-container p {
    font-size: medium;
    font-weight: normal;
}