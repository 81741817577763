.crm-left-part {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.crm-right-part {
    display: flex;
    flex: 3;
    flex-direction: column;
}
.crm-title {
    font-size: 21px;
    /* font-weight: bold; */
    padding: 10px;
}
.crm-container {
    display: flex;
    /* flex: 1; */
    /* flex-direction: column; */
}
.crm-btn-container {
    display: flex;
    flex-direction: column;
    margin-right: 35px;
}
.crm-btn-img-container {
    display: flex;
    flex: 1;
}
.crm-access-container {
    display: flex;
    flex: 5;
    flex-direction: column;
}
#api-key-title {
    height: 40px;
}
.crm-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.crm-btn-save:hover {
    /* background: white; */
    /* border: 3px solid #312f2f; */
    /* color: #312f2f; */
    /* cursor: pointer; */
}
.crm-btn-save {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--vertFonceAssistant);
    padding: 12px;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-weight: normal;
    width: 145px;
    height: 50px;
    font-size: math;
    margin-top: 15px;
}
.crm-btn-verification:hover {
    background: white;
    border: 3px solid #312f2f;
    color: #312f2f;
    cursor: pointer;
}
.crm-btn-verification {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--jauneClaire);
    padding: 12px;
    border: 2px solid white;
    border-radius: 20px;
    color: black;
    /* width: 145px;
    height: 50px; */
    font-size: math;
    margin-top: 15px;
}
.crm-row-input-and-img {
    display: flex;
}
.crm-input-container {
    display: flex;
    flex: 9;
}
.crm-img-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.crm-first-image {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.crm-btn-main-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.crm-welcome-title {
    display: flex;
    font-size: xx-large;
    text-align: center;
}
.crm-text-p {
    display: flex;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: large;
}