.error-field {
    border-color: red;
}

.no-error {
    /* border-color: transparent; */
}

.container-error {
    border: 1px solid red;
    border-radius: 5px;
}
.container-no-error {
    border: none;
}