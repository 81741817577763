@import url("Colors.css");
.dash-main-container {
    display: flex;
    flex: 1;
    height: 100vh;
    background-image: url(/public/images/fond/sso_main_bg.svg);
}
.dash-wrapper {
    display: flex;
    flex: 5;
    flex-direction: column;
}

#Campagn-submenu {
    padding-left: 25px;
    background: #ffffff08;
}

.dash-left-part {
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    background: var(--grisSombre);
    width: 15vw;
}
.dash-right-part {
    display: flex;
    flex: 6;
    flex-direction: column;
    /* margin-left: 40px;
    margin-right: 40px;
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 20px; */
    padding-left: 2%;
    padding-right: 2%;
    width: 80%;
}

.dash-right-part-campagn-history {
    display: flex;
    flex: 6;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    padding-left: 2%;
    padding-right: 2%;
    width: 80%;
}

.dash-right-part-title {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: x-large;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    font-weight: bold;
}
.left-picto {
    display: flex;
    /* flex: 1; */
    width: 50px;
    height: 50px;
    /* border: 1px solid;
    border-radius: 15px; */
    justify-content: center;
    align-items: center;
}
.right-text {
    display: flex;
    flex: 3;
    align-items: center;
    padding-left: 10px;
}
.picto-meeting {
    width: 30px;
    height: 30px;
}
#form-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    margin: 5%;
    border: 2px solid gray;
    border-radius: 20px;
    padding: 1%;
}

#form-left-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* background: rgb(251, 245, 245); */
    padding: 2%;
}
#form-separator-line {
    display: flex;
    width: 1px;
    background: #757373;
}
#dash-form-right-content {
    display: flex;
    flex: 3;
    flex-direction: row;
    /* padding: 2%; */
}
#dash-title {
    display: flex;
    justify-content: left;
    font-size: 25px;
}
.dash-alert-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dash-alert-img {
    display: flex;
    justify-content: center;
}

#dash-alert-title {
    text-align: center;
    padding: 15px;
    color: var(--rouge);
}
.dash-alert-p {
    text-align: center;
    font-size: large;
}

#form-input{
    display: flex;
    flex-direction: column;
    flex: 4;
}
.row{
    display: flex;
    flex: 1;
    flex-direction: column;
    /* margin-bottom: 10px; */
}
.dest-row{
    display: flex;
    flex-direction: column;
}
.col1{
    display: flex;
    flex: 1;
    flex-direction: row;
}
label {
    font-weight: bold;
    padding: 5px;
}
.input_text{
    height: 45px;
    border-radius: 15px;
    border: 1px solid gray;
    padding: 20px;
    font-size: medium;
}
#form-btn-container{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

#form-btn {
    background: var(--grisSombre);
    padding: 15px;
    font-size: medium;
    border-radius: 15px;
    color: var(--jauneClaire);
    font-weight: bold;
}
#form-btn:hover {
    cursor: pointer;
    padding: 15px;
    font-size: medium;
    color: black;
    background: var(--jauneClaire);
    padding: 15px;
    border: 2px solid var(--grisSombre);
}
#custom-select {
    padding: 10px;
    background: transparent;
    border: 1px solid #978e8e;
    border-radius: 10px;
}
#credit-restant {
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    color: var(--gris);
    /* padding-left: 15px; */
}
#credit-value {
    color: white;
    padding-left: 2%;
}

.left-part-footer {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: small;
    font-weight: bold;
    text-align: center;
    color: white;
}

.box-form {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
}
.box-form-desc {
    margin-bottom: 25px;
    margin-right: 10px;
}