.welcoming-main-page {
    background-image: url(/public/images/fond/welcoming_bg.svg);
    display: flex;
    flex: 1;
    flex-direction: 'row';
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

.welcome-left-container {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.welcome-left-intro-container {
    display: flex;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 80px;
}

.welcome-left-credit-container {
    display: flex;
    margin-left: 80px;
    margin-right: 140px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.welcome-left-btn-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
}

.trial-modal-container {
    padding-left: 50px;
    padding-right: 50px;
}

.trial-module-list-container {
    display: flex;
    flex: 5 1 0%;
    flex-direction: column;
}
.trial-module-list-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.trial-module-list-module-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trial-module-label {
    color: white;
}

.welcome-text {
    font-size: 35px;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.welcome-big-text {
    text-align: center;
    font-size: 50px;
    padding-left: 15px;
    padding-bottom: 10px;
}
.welcome-begin {
    text-align: left;
    font: normal normal normal 20px/36px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
}
.welcome-trial-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--violet);
    color: white;
    margin-left: 70px;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid transparent;
    cursor: pointer;
}